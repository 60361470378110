(function($) {
  $(document).ready(function() {
    if(window.BorlabsCookie.checkCookieGroupConsent('statistics') == true) {
      $('.open-contact-overlay').click(function() {
        ga('send', 'event', 'sidepanel', 'click', 'kontakt');
      });
      $('#sidepanel ul li:nth-child(4)').click(function() {
        ga('send', 'event', 'sidepanel', 'click', 'probetraining');
      });
      $('#sidepanel ul li:nth-child(2)').click(function() {
        ga('send', 'event', 'sidepanel', 'click', 'kursplan');
      });
      $('#sidepanel ul li:nth-child(3)').click(function() {
        ga('send', 'event', 'sidepanel', 'click', 'beratungstermin');
      });
      $('#slider-eyecatcher .button').click(function() {
        ga('send', 'event', 'startseite', 'click', 'stoerer');
      });
      $('.elements2go-shortcode .button').click(function() {
        ga('send', 'event', 'elements2go', 'click', 'elements2go-my-elements');
      });
      $('.widget.elements2go .button').click(function() {
        ga('send', 'event', 'elements2go', 'click', 'elements2go-widget-startseite');
      });
      $('body.elements2go .button[href="#login"]').click(function() {
        ga('send', 'event', 'elements2go', 'click', 'elements2go-landingpage-button');
      });
      $('.trial-table a[href*="#betreutes-probetraining"]').click(function() {
        ga('send', 'event', 'probetraining-tabelle', 'click', 'betreutes-probetraining');
      });
      $('.trial-table a[href*="#probetraining"]').click(function() {
        ga('send', 'event', 'probetraining-tabelle', 'click', 'probetraining');
      });
      $('.trial-table a[href*="#beratungstermin"]').click(function() {
        ga('send', 'event', 'probetraining-tabelle', 'click', 'beratungstermin');
      });
      function sendEventContactForm() {
        if($('body.home .content-container .contact-form-wrapper .nf-response-msg p').length > 0) {
          ga('send', 'event', 'startseite', 'click', 'kontaktformular-startseite');
        } else {
          setTimeout(sendEventContactForm, 2500);
        }
      }
      sendEventContactForm();
    }
  });
})(jQuery);
